<template>
  <v-app-bar
    id="app-bar"
    absolute
    app
    color="rgb(71 130 180)"
    flat
    height="65"
    style="color: white;"
  >
    <v-btn
      class="mr-3"
      elevation="1"
      fab
      small
      @click="setDrawer(!drawer)"
    >
      <v-icon v-if="drawer">
        mdi-arrow-left
      </v-icon>
      
      <v-icon v-else>
        mdi-arrow-right
      </v-icon>
    </v-btn>

    <v-toolbar-title
      class="hidden-sm-and-down font-weight-light"
      v-text="$route.name"
    />

    <v-spacer />



    <div class="mx-3" />
    Bem vindo, {{this.user}}! Seu último acesso foi em {{this.data}}
    
    <v-btn
      class="ml-2"
      min-width="0"
      text
      to="/register">
      <v-icon class="white--text">mdi-key</v-icon>
    </v-btn>

    <template>
      <v-menu bottom left offset-y origin="top right" transition="scale-transition">
        <template v-slot:activator="{ attrs, on }">
          <v-btn class="ml-2" min-width="0" text v-bind="attrs" v-on="on">
            <v-badge color="orange" overlap v-if="count_notify > 0">
              <template v-slot:badge>
                <span style="font-size: 10px; width: 8px">{{ count_notify }}</span>
              </template>
              <v-icon class="white--text">mdi-bell</v-icon>
            </v-badge>
            <v-icon class="white--text" v-else>mdi-bell</v-icon>
          </v-btn>
        </template>
    
        <v-list :tile="false" nav v-if="count_notify > 0" style="max-width: 360px;">
          <div>
            <app-bar-item v-for="(notification, i) in notifications" :key="`item-${i}`">
              <v-list-item-content style="max-width: 300px;" @click="handleCardClick(notification)"> <!-- Definindo a largura máxima -->
                
                <v-list-item-title style="white-space: normal; font-size: 14px;">{{ notification.titulo }}</v-list-item-title>
                <v-list-item-subtitle style="white-space: normal; font-size: 12px;">{{ notification.mensagem }}</v-list-item-subtitle>
                <div :style="{ 'margin-top': '4px', 'font-size': '12px', 'color': getTipoColor(notification.tipo) }">
                  <v-icon :style="{ 'font-size': '12px', 'color': getTipoColor(notification.tipo) }">mdi-circle</v-icon>
                    {{ notification.tipo }}
                 
                  </div>
                
              </v-list-item-content>
            </app-bar-item>
            
            </div>
        </v-list>
      </v-menu>
    </template>
    
    

 


    <v-btn
      class="ml-2"
      min-width="0"
      text
      to="login">
      <v-icon class="white--text">mdi-logout</v-icon>
    </v-btn>


  </v-app-bar>
</template>

<script>
  // Components
  import { VHover, VListItem } from 'vuetify/lib'
  import Notificacao from '../../../../services/notificacao'

  // Utilities
  import { mapState, mapMutations } from 'vuex'

  export default {
    name: 'DashboardCoreAppBar',

    components: {
      AppBarItem: {
        render (h) {
          return h(VHover, {
            scopedSlots: {
              default: ({ hover }) => {
                return h(VListItem, {
                  attrs: this.$attrs,
                  class: {
                    'black--text': !hover,
                    'white--text secondary elevation-12': hover,
                  },
                  props: {
                    activeClass: '',
                    dark: hover,
                    link: true,
                    ...this.$attrs,
                  },
                }, this.$slots.default)
              },
            },
          })
        },
      },
    },

    props: {
      value: {
        type: Boolean,
        default: true,
      },
    },

    data: () => ({
      notifications: [
       // { titulo: 'Notification Title 1', message: 'Mike John Responded to your email' },
       // { title: 'Notification Title 2', message: 'You have 5 new tasks' },
       // { title: 'Notification Title 3', message: 'You\'re now friends with Andrew' },
       // { title: 'Notification Title 4', message: 'Another Notification' },
       // { title: 'Notification Title 5', message: 'Another one' },
      ],
      count_notify: 5,
      user:'',
      data: '',
    }),
   
    computed: {
      ...mapState(['drawer']),
    },

    mounted() {
     this.user = JSON.parse(sessionStorage.getItem('user')).nome;
     this.data = JSON.parse(sessionStorage.getItem('user')).acesso_previous;
     this.lista_notificacao();
    },

    methods: {
      
      ...mapMutations({
        setDrawer: 'SET_DRAWER',
      }),

      getTipoColor(tipo) {
          if (tipo === 'Novidade') {
            return 'green';
          } else if (tipo === 'Correção') {
            return 'red';
          } else if (tipo === 'Alteração') {
            return 'orange';
          } else {
            // Cor padrão caso não seja nenhum dos valores esperados
            return 'black';
          }
        },
     

      logout(){
          sessionStorage.removeItem('user')
          to: login;
      },


      handleCardClick(notification) {
       

          Notificacao.notificado_confirmado(notification.id)
            .then(response => {
                      if (response.status === 200) {
                        const indexToRemove = this.notifications.indexOf(notification);
                          if (indexToRemove !== -1) {
                            this.notifications.splice(indexToRemove, 1);
                          }                          
                      
                        this.count_notify = this.notifications.length;
                        this.$router.push(notification.pagina);
                      }
                    })
                    .catch(error => {
                    }) .finally(() => {
                    
                      
                    });
        },


     
      
      lista_notificacao(){
        Notificacao.lista_notificacao()
          .then(response => {
                    if (response.status === 200) {
                      this.notifications = response.data;
                    }
                  })
                  .catch(error => {
                    // Lida com erros
                  }) .finally(() => {
                    // Após o término do download, oculte o diálogo de progresso
                    this.showProgress = false;
                    this.count_notify = this.notifications.length;
                  });
      },

    },



  }
</script>
