import { http } from './config'
import authHeader from './auth-header';

export default {


    

    criar_notificacao: (notificacao) => {
      return http.post('notificacao-dash/salva/', notificacao, { headers: authHeader() });
    },

   


    lista_notificacao: () => {
      
      return http.get('/notificacao-dash/minhas-notificacoes', {
        headers: authHeader() // Certifique-se de incluir os cabeçalhos de autenticação aqui
      });
    },

    notificado_confirmado: (notificado) => {
      const params = {
          'notificado': notificado,
      }
      return http.get('/notificacao-dash/notificado-confirmado/', {
         params: params,
         headers: authHeader()   
      })
  },


}
